import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import { fetchPaymentStatusAction } from "../state/paymentStatus.reducer";
import AppPageHeader from "../components/common/texts/appPageHeader";
import { Loading } from "../components/common/loading";
import { LINKS } from "../constants/links";
import { getQueryParam } from "../utils/queryParams";
import { EnumPaymentStatus } from "../constants/paymentStatuses";
import { AppContainer } from "../components/common/appContainer";

const PaymentStatus = () => {
	const refreshTime = 2000;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const paymentStatus = useSelector((state) => state.paymentStatus);
	const { orderId, status, withInvoice, error } = paymentStatus;
	const serviceQueryId = getQueryParam("ServiceID");
	const orderQueryId = getQueryParam("OrderID");
	let interval;

	const fetchPaymentStatus = async () => {
		dispatch(fetchPaymentStatusAction(serviceQueryId, orderQueryId));
	};

	useEffect(() => {
		interval = setInterval(fetchPaymentStatus, refreshTime);
		return () => {
			clearInterval(interval);
			interval = null;
		};
	}, []);

	useEffect(() => {
		if (status === EnumPaymentStatus.FAILURE || error) {
			navigate(LINKS.PAYMENT_FAILED);
		}
		if (status === EnumPaymentStatus.SUCCESS) {
			navigate(`${LINKS.PAYMENT_SUCCESS + orderId}&withInvoice=${withInvoice ? "1" : "0"}`);
		}
	}, [status, error]);

	return (
		<>
			<SEO title={t("paymentStatus.title")} />
			<AppContainer>
				<AppPageHeader>{t("paymentStatus.header")}</AppPageHeader>
				<Loading transparentBg />
			</AppContainer>
		</>
	);
};

export default PaymentStatus;
